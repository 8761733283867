import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import BeatLoader from 'react-spinners/BeatLoader';
import * as actions from './redux/actions';
import ClientProfileForm from './ClientProfileForm';
import Layout from './Layout';
export class ClientProfile extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  handleCountryChange = (selectedOption) => {
    this.setState({ selectedCountry: selectedOption });
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { actions } = this.props;
    setSubmitting(true);
    const { firstName, lastName, country, businessName } = values;
    const data = {
      firstName,
      lastName,
      country,
      businessName,
    };
    actions.updateProfile({ data }).then(() => setSubmitting(false));
  };

  deleteProfile = async () => {
    const { actions, history } = this.props;
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      text: "You're about to permanently delete your account. If you're ready to delete it, click Confirm.",
    }).then((result) => {
      if (result.value) {
        actions.deleteClientProfile().then((res) => {
          history.push('/');
        });
      }
    });
  };

  showForm = () => {
    const { home } = this.props;
    const { updateProfilePending, clientDetail, orgClient } = home;
    const { username, country, firstName, lastName, registerAddress, gst, panNo } = clientDetail;
    const { name } = orgClient;

    let businessName = clientDetail.businessName;
    businessName = businessName || name;

    const initialValues = {
      username,
      firstName: firstName,
      lastName: lastName,
      businessName,
      registerAddress,
      gst,
      panNo,
      country,
    };
    return (
      <ClientProfileForm
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        updateProfilePending={updateProfilePending}
        handleCountryChange={this.handleCountryChange}
        onDeleteProfile={this.deleteProfile}
      />
    );
  };

  render() {
    const { home } = this.props;
    const { clientDetailsPending, clientOrganizationPending } = home;

    return (
      <Layout currentPage="Profile">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12 col-xl-6 offset-xl-3">
                <div className="card card-plain h-100 shadow-lg">
                  <div className="card-header pb-0 p-3">
                    <div className="row">
                      <div className="col-md-8 d-flex align-items-center">
                        <h4 className="mb-0">Profile Information</h4>
                      </div>
                    </div>
                  </div>
                  <hr class="dark horizontal my-3" />
                  <div className="card-body">
                    {(clientDetailsPending || clientOrganizationPending) && (
                      <BeatLoader
                        css={{ display: 'inline-block' }}
                        size={'15px'}
                        margin={'5px'}
                        color={'#344767'}
                        loading={clientDetailsPending || clientOrganizationPending}
                      />
                    )}

                    {this.showForm()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);
