import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { Link, NavLink } from 'react-router-dom';
import voca from 'voca';
import * as actions from './redux/actions';
import SocialMediaLinks from './SocialMediaLinks';
import ResourceLinks from './ResourceLinks';
import CustomPagination from '../../common/CustomPagination';
import VideoModal from './VideoModal';
import Layout from './Layout';
const css = {
  margin: 'auto',
  marginTop: 'revert',
  display: 'block',
};
export class Dashboard extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    currentPage: 1,
    perPage: 12,
    showVideoModal: false,
  };

  componentDidMount() {
    document.title = 'Dashboard - Drapcode';
    const { actions } = this.props;
    const { currentPage, perPage } = this.state;
    actions.getAllProjects({ page: currentPage - 1, size: perPage });
  }

  getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const day = date.getDate();
    const ordinalSuffix = this.getOrdinalSuffix(day);
    return formattedDate.replace(/\b\d+\b/, (match) => match + ordinalSuffix);
  };
  handlePageChange = (page) => {
    const { actions } = this.props;
    const { perPage } = this.state;
    const { projectMetadata } = this.props.home;

    const newPage = Math.max(1, Math.min(page, Math.ceil(projectMetadata.totalProjects / perPage)));

    this.setState({ currentPage: newPage }, () => {
      actions.getAllProjects({ page: newPage - 1, size: perPage });
    });
  };

  openVideoModal = () => {
    this.setState({ showVideoModal: true });
  };

  closeVideoModal = () => {
    this.setState({ showVideoModal: false });
  };

  renderProjects() {
    const { login, home, protocol, builderSurface, exchange_surface } = this.props;
    const token = login.token;
    const { orgClient, projectMetadata } = home;
    const { content } = projectMetadata.filteredProjects || {};
    if (!content) {
      return null;
    }
    return content.map((project, index) => (
      <div className="col-lg-4 col-md-6 mt-4 mb-4" key={index}>
        <div className="card">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-10 col-10">
                <h3
                  className="text-dark font-weight-bold mb-0"
                  title={`${voca.titleCase(project.name)}`}
                >
                  {voca.truncate(voca.titleCase(project.name), 25)}
                </h3>
              </div>
              <div className="col-lg-2 col-2 my-auto text-end">
                <Link
                  to={`/client/${project.uuid}/project-detail`}
                  className="setting-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Project Details"
                  style={{ textDecoration: 'none', color: '#344767' }}
                >
                  <i className="fas fa-cog " style={{ cursor: 'pointer', fontSize: '21px' }}></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="timeline-one-side">
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="material-icons text-primary text-gradient">watch_later</i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    {this.formatDate(new Date(project.dateCreated))}
                  </h6>
                </div>
              </div>
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="material-icons text-danger text-gradient">code</i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    {project.framework
                      ? `${project.framework.name} ${project.framework.version}`
                      : 'N/A'}
                  </h6>
                </div>
              </div>
            </div>
            <hr className="dark horizontal"></hr>
            <div className="d-flex mx-2 justify-content-between">
              <a
                href={`${protocol}://${project.seoName}.${builderSurface}editor/${1}/v/${
                  project.uuid
                }/loader?token=${token}`}
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Configure Project"
                className="btn btn-sm btn-success text-white"
              >
                <i className="fas fa-cog " style={{ fontSize: '12px' }}></i> Project Builder
              </a>
              <a
                href={`${protocol}://${project.seoName}.${exchange_surface}`}
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Live App"
                className="btn btn-sm  view-button"
              >
                <i className="fas fa-eye" style={{ fontSize: '11px' }}></i> View
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  calculateProjectStepPer = (project) => {
    if (!project) {
      return 0;
    }
    const { steps } = project;
    if (!steps || steps.length === 0) {
      return 0;
    }
    const perComplete = steps.map((step) => step.value).reduce((acc, step) => acc + step);
    return perComplete;
  };

  renderCreateProjectLink = () => {
    const { home } = this.props;
    const { orgClient } = home;
    const { projectCountAllowed, projects, subscription } = orgClient;
    if (subscription === 'FREE') {
      return <h5>Please upgrade your account or contact </h5>;
    }

    let isAllowed = false;
    if (!projects || projects.length <= 0) {
      isAllowed = true;
    } else {
      isAllowed = projects.length < projectCountAllowed;
    }

    if (!isAllowed) {
      return <p>Free project limit exceeded.</p>;
    }
    return (
      <Link
        className="btn btn-success text-white btn-md mb-0 me-3 mt-5"
        to={'/client/create-project-select'}
      >
        <i className="fa fa-plus me-sm-1"></i>
        Create Project
      </Link>
    );
  };

  zeroProjectUI = () => {
    const { home } = this.props;
    const { projectMetadata, getAllProjectsPending } = home;
    if (getAllProjectsPending) {
      return (
        <div className="row mt-4">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
              <BeatLoader
                css={{ display: 'inline-block' }}
                size={'15px'}
                margin={'5px'}
                color={'#344767'}
                loading={getAllProjectsPending}
              />
            </div>
          </div>
        </div>
      );
    } else {
      const { totalProjects } = projectMetadata ? projectMetadata : { totalProjects: 0 };

      if (totalProjects !== 0) {
        console.log(`I have ${totalProjects} project/s so return`);
        return '';
      }
      return (
        <div className="row mt-4">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
              <h3>Welcome!</h3>
              <p className="text-dark" style={{ fontSize: 'large' }}>
                We are excited to commence your DrapCode journey! Get ready
                <br /> to give your cool ideas a concrete shape by building amazing
                <br /> web apps.
              </p>
              {/* don't remove this comment */}
              {/* <button className="btn btn-primary p-2" onClick={this.openVideoModal}>
              <img
                src="https://app.drapcode.com/public/maxresdefault%20(1).jpg"
                style={{ width: '480px' }}
                alt="Short Intro Video"
              />
            </button> */}
              {this.renderCreateProjectLink()}
            </div>
          </div>
          {/* don't remove this cmt */}
          {/* <ResourceLinks protocol={protocol} projectDomain={project_domain} /> */}
          {/* <SocialMediaLinks />{' '} */}
        </div>
      );
    }
  };

  nonZeroProjectUI = () => {
    const { home } = this.props;
    const { projectMetadata } = home;
    const { totalProjects, filteredProjects } = projectMetadata
      ? projectMetadata
      : { totalProjects: 0 };

    if (totalProjects === 0) {
      console.log(`I have zero project so return`);
      return '';
    }
    const { totalPages } = filteredProjects ? filteredProjects : 0;
    const { currentPage, perPage, showVideoModal } = this.state;
    return (
      <>
        <div className="row mt-4">
          <div className="tm-section-title custom-h1-tm-section-title d-flex justify-content-end mb-3">
            {this.renderCreateProjectLink()}
          </div>
          {this.renderProjects()}
        </div>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={this.handlePageChange}
        />
        <VideoModal isOpen={showVideoModal} toggle={this.closeVideoModal} />
      </>
    );
  };

  render() {
    const { home } = this.props;
    const { clientDetail } = home;
    if (!clientDetail) {
      return;
    }

    const { status } = clientDetail;
    if (status === 'AWAITING_APPROVAL') {
      return (
        <div className="row mt-4">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
              <h3>Welcome!</h3>
              <p className="text-dark" style={{ fontSize: 'large' }}>
                Your account is not approved. Please contact Admin.
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Layout currentPage="Dashboard">
        {this.zeroProjectUI()}
        {this.nonZeroProjectUI()}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    builderSurface: process.env.REACT_APP_BUILDER_SURFACE,
    configurationSurface: process.env.REACT_APP_CONFIGURATION_SURFACE,
    exchange_surface: process.env.REACT_APP_EXCHANGE_SURFACE,
    project_domain: process.env.REACT_APP_PROJECT_DOMAIN,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
