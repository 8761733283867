import React from 'react';

const YourTeam = ({ members }) => {
  if (!members || members.length === 0) return '';
  return (
    <div className="card card-plain h-100 shadow-lg">
      <div className="card-body">
        <h5 className="font-weight-bolder">Your organization's members</h5>
        <hr class="dark horizontal my-4" />
        <div className="table-responsive p-0">
          <table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Name
                </th>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Email
                </th>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.uuid}>
                  <td className="align-middle text-center">
                    {member.firstName} {member.lastName}
                  </td>
                  <td className="align-middle text-center">{member.username}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default YourTeam;
