import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';
import Invites from './teams/PendingRequest';
import OrganizationList from './teams/OrganizationList';
import YourTeam from './teams/YourTeam';

export class Team extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    //Load Pending Invites
    actions.loadPendingInvite();
    //Load list of organization user is member
    actions.loadMemberOrgs();
    //Load list of member of your organization
    actions.loadOrgMembers();
  }

  changeInviteStatus = (data) => {
    this.props.actions.changeRequestStatus(data).then((data) => {
      if (data.status == '') {
        //Load list of organization user is member
        actions.loadMemberOrgs();
        //Load list of member of your organization
        actions.loadOrgMembers();
      }
    });
  };

  render() {
    const { actions, home } = this.props;
    const { pendingInvites, orgClient, memberOrgs, orgMembers, clientDetail } = home;
    const { changeRequestStatus } = actions;
    return (
      <Layout currentPage="Teams">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12 col-xl-6 offset-xl-3">
                <Invites
                  pendingInvites={pendingInvites}
                  changeInviteStatus={this.changeInviteStatus}
                />
                <OrganizationList organizations={memberOrgs} clientDetail={clientDetail} />
                <YourTeam members={orgMembers} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);
