import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Link, NavLink } from 'react-router-dom';
import cookie from 'react-cookies';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-white.svg';
export class ClientSidebar extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  handleLogout = () => {
    cookie.remove('token', { path: '/' });
    cookie.remove('name', { path: '/' });
    cookie.remove('role', { path: '/' });
    cookie.remove('uuid', { path: '/' });
    cookie.remove('username', { path: '/' });
    this.props.logout();
    this.props.history.push('/');
  };

  hideSidebarNav = () => {
    document.body.classList.toggle('g-sidenav-pinned');
  };

  render() {
    return (
      <aside
        className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
            onClick={this.hideSidebarNav}
          ></i>
          <NavLink className="navbar-brand m-0" to="/dashboard">
            <img src={drapcodeLogo} alt="Logo" className="custom-logo-pass" />
          </NavLink>
        </div>
        <hr className="horizontal light mt-0 mb-2" />
        <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link text-white" activeClassName="active" to="/dashboard">
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">dashboard</i>
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link text-white" to="/client/accounts">
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">settings</i>
                </div>
                <span className="nav-link-text ms-1">Account</span>
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link text-white " to="/client/profile">
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">person</i>
                </div>
                <span className="nav-link-text ms-1">Profile</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white " to="/client/teams">
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">people</i>
                </div>
                <span className="nav-link-text ms-1">Team</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white"
                href="https://academy.drapcode.com/"
                target="_blank"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">description</i>
                </div>
                <span className="nav-link-text ms-1">Documentation</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white"
                href="https://community.drapcode.com/"
                target="_blank"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">people</i>
                </div>
                <span className="nav-link-text ms-1">Community</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="" className="nav-link text-white" onClick={this.handleLogout}>
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="material-icons opacity-10">exit_to_app</i>
                </div>
                <span className="nav-link-text ms-1">Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="sidenav-footer position-absolute w-100 bottom-0 "></div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    logout: () => dispatch({ type: 'LOGIN_LOGOUT_REQUEST' }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSidebar);
